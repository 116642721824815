<template>
  <div>
    <portal to="mobileLeftAppBar">
      <div class="d-flex align-center">
        <v-btn
          color="primary"
          fab
          small
          elevation="0"
          @click="handleNewDocument()"
        >
          <app-icon>add</app-icon>
        </v-btn>
      </div>
    </portal>

    <v-row no-gutters>
      <template v-for="(document, index) in documents">
        <v-col cols="12" class="pa-3" :key="index">
          <v-card class="pa-5" outlined>
            <div
              class="d-flex justify-space-between align-center document-name"
            >
              <span>{{ document.name }}</span>
              <v-btn icon small @click="handleEditDocument(document.id)">
                <v-icon center> mdi-pencil </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documents: [],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    handleEditDocument(document_id) {
      this.$router.push(`/configuracoes/documentos/${document_id}`);
    },

    handleNewDocument() {
      this.$router.push(`/configuracoes/documentos/novo`);
    },

    select() {
      this.loading = true;
      this.$http.index("document/document").then((response) => {
        this.documents = response.documents;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.document-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>