<template>
  <div>
    <template v-if="isMobile"> <Mobile /> </template>
    <template v-if="!isMobile"> <Desktop /> </template>
  </div>
</template>

<script>
import Desktop from "@/components/documents/views/Desktop.vue";
import Mobile from "@/components/documents/views/Mobile.vue";

export default {
  components: {
    Desktop,
    Mobile,
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style>
</style>

<!--template>
  <div>
    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn
          @click="$router.push('/configuracoes/documentos/novo')"
          
          bottom
          large
          color="primary"
          >Novo Modelo</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="documents"
      :loading="loading"
      @click:row="openDocumentForm($event.id)"
      hide-default-footer
      :items-per-page="-1"
      disable-sort
    >
      <template v-slot:[`item.action`]="{ item }">
        <app-delete-btn @click="deleteItem(item.id)" type="icon" />
      </template>
    </v-data-table>
  </div>
</-template>

<script>
export default {
  data() {
    return {
      documents: [],
      loading: false,
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "", align: "end", value: "action" },
        ,
      ],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    openDocumentForm(document_id) {
      this.$router.push(`/configuracoes/documentos/${document_id}`);
    },

    select() {
      this.loading = true;
      this.$http.index("document/document").then((response) => {
        this.documents = response.documents;
        this.loading = false;
      });
    },
    deleteItem(document_id) {
      this.$loading.start();
      this.$http
        .destroy("document/document", document_id)
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>