<template>
  <div>
    <v-card outlined>
      <v-card-title
        class="d-flex justify-space-between align-center"
        style="padding-bottom: 10px !important"
      >
        <app-title> Configurações de Documentos </app-title>
        <v-btn rounded color="primary" @click="handleNewDocument()">
          <v-icon left> mdi-plus </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-card-text class="px-3">
        <v-row no-gutters>
          <template v-for="(document, index) in documents">
            <v-col cols="4" class="pa-3" :key="index">
              <v-card class="pa-5" outlined>
                <div
                  class="d-flex justify-space-between align-center document-name"
                >
                  <span>{{ document.name }}</span>
                  <v-btn icon small @click="handleEditDocument(document.id)">
                    <v-icon center> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      documents: [],
    };
  },
  mounted() {
    this.select();
  },
  methods: {
    handleEditDocument(document_id) {
      this.$router.push(`/configuracoes/documentos/${document_id}`);
    },

    handleNewDocument() {
      this.$router.push(`/configuracoes/documentos/novo`);
    },

    select(page = 1) {
      this.$http.index("document/document", { page: page }).then((response) => {
        this.documents = response.documents;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.document-name {
  color: #33303e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
</style>